<template lang="pug">
  div
    .explain.content(v-show='type == 1')
      .title 權益須知
      .d-flex.flex-wrap.mb-3
        .subtitle.col-12.col-md-4
          span.subtitle-text.badge.badge-pill.badge-primary
            | 升級成功
        .text.col-12.col-md-8.pl-md-0
          | 本測驗結果
          span.blue-text 高於80分(含)即通過
          | ，系統將自動為您調升等級。調升後，您的經驗值將重新累計。
      .d-flex.flex-wrap.mb-3
        .subtitle.col-12.col-md-4
          span.subtitle-text.badge.badge-pill.badge-primary
            | 升級失敗
        .text.col-12.col-md-8.pl-md-0
          | 若本測驗結果低於80分，將依挑戰次數扣除經驗值（見下表），再次挑戰需重新累積經驗值，才能再次進行升級挑戰。您於閱卷期間所進行之課程，繼續納入經驗值計算。
          table.mt-2
            tr
              th 挑戰次數
              th 經驗值扣除百分比
            tr
              td 第一次挑戰失敗
              td 10%
            tr
              td 第二次挑戰失敗
              td 15%
            tr
              td 第三次挑戰失敗
              td 20%
            tr
              td 第四次挑戰失敗
              td 25%
            tr
              td 第五次挑戰失敗
              td 30%
      .d-flex.flex-wrap.mb-3
        .subtitle.col-12.col-md-4
          span.subtitle-text.badge.badge-pill.badge-primary
            | 閱卷時間
        .text.col-12.col-md-8.pl-md-0
          | 測驗結果將於7個工作天內於WUWOW學員後台通知，登入後即可查看。
      .d-flex.flex-wrap.mb-4
        .subtitle.col-12.col-md-4
          span.subtitle-text.badge.badge-pill.badge-primary
            | 其他
        .text.col-12.col-md-8.pl-md-0
          | 本測驗並非唯一升級方式,您亦可通過「累積3名顧問升級推薦」提升您的等級。
      .mt-4
        b-button.btn(variant='primary' @click='type=2') {{$t('next_page')}}
      div
        b-button.btn(variant='primary' @click='toBack') {{$t('return')}}

    .question-description.content(v-show='type == 2')
      .title 試題說明
      .mb-4
        | 本試題共計12題
        br
        | 挑戰等級2至等級6，限時作答15分鐘
        br
        | 挑戰等級7至等級9，限時作答20分鐘
        br
        | 題目分為下列兩大部分：
        .subtitle.col-12.col-md-5.col-xl-5
          span.subtitle-text.badge.badge-pill.badge-primary
            | 第一部分 單字文法
        .text.col-12.col-md-7.col-xl-7.pl-xl-0
          | 單字測驗（共5題）-請填入正確選項
          br
          | 文法測驗（共5題）-請填入正確選項
      .d-flex.flex-wrap.mb-4
        .subtitle.col-12.col-md-5.col-xl-5
          span.subtitle-text.badge.badge-pill.badge-primary
            | 第二部分 &emsp;口說
        .text.col-12.col-md-7.col-xl-7.pl-xl-0
          | 問答題（共2題）-錄音口說回答題
          br
          | (請完整表述您的答案，答題時間為一分鐘)
      .mt-4
        b-button.btn(variant='primary' @click='type=3') {{$t('next_page')}}
      div
        b-button.btn(variant='primary' @click='type=1') {{$t('return')}}

    //- 檢查設備 upgrade_test_explain_recorder.vue
    .device-check.content(v-show='type == 3')
      .title 檢查您的設備
      .mb-4
        | 因本試題題型包含口說題目，請在考試前檢查
        br
        | 您的麥克風是否正常，並於安靜無雜音的環境中進行考試(以免干擾閱卷老師批改)。
      explain-recorder-test
      .mt-4
        b-button.btn(variant='primary' @click='toStartTestPage()') {{$t('check_mic')}}
      div
        b-button.btn(variant='primary' @click='type=2') {{$t('return')}}
    //- 進入挑戰
    .start.content(v-show='type == 4')
      .title(v-if='$route.params.startTest') {{$t('startTest')}}
      .title(v-else='') {{$t('end_read')}}
      img.mb-4(src='https://cdn2.wuwow.tw/pictures/upgrade-test/start.svg' alt='')
      div 您已閱讀完挑戰說明
      div(v-show='$route.params.startTest') 按下「進入挑戰」開始進行測驗
      .d-flex.flex-column.align-items-center.mt-4(v-if='$route.params.startTest')
        //- 下面要補上這句：v-if='challengeInfo.in_exam_flag'
        b-button.btn(variant='primary' @click='toStartTest') 回到挑戰
        //- 下面要補上這句： v-else='' :disabled='!challengeInfo.rtn'
        b-button.btn(variant='primary' @click='showStartTestModal') 進入挑戰
        b-button.btn(variant='primary' :to="{ name: 'UpgradeTestList'}") 想再準備準備
      .mt-4(v-else='')
        b-button.btn(variant='primary' :to="{ name: 'UpgradeTestList' }") {{$t('end_read')}}
      b-modal.start-test-modal(v-model='show_start_test_modal' title='確認進入挑戰?' centered='' header-text-variant='light' header-bg-variant='primary' @ok='toStartTest()' ok-title='是' cancel-title='否')
        i.fa.fa-exclamation-triangle.fa-4x.warning-icon(aria-hidden='true')
        br
        // {{ $t('startModalText') }}
        | 注意!點選進入挑戰後我們將扣除您{{deduction_exp}}經驗值! <
        br
        | 確定要繼續嗎?
        br
        | (本操作無法復原)
    .btm.d-flex.justify-content-between
      .circle
      div(:class="[type>=2?'circle':'circle-off']")
      div(:class="[type>=3?'circle':'circle-off']")
      div(:class="[type>=4?'circle':'circle-off']")

</template>

<script type="text/javascript">
import { ViewExplainLang } from '../lang.js';

export default {
  name: 'InnerPage',

  components: {
  },

  i18n: ViewExplainLang,

  data() {
    return {
      type: 1,
      data: '',
      config: '',
      show_start_test_modal: false,
      deduction_exp: '',
    };
  },

  computed: {

  },

  watch: {},

  created() {
  },

  methods: {
    toBack(){
      this.$router.go(-1);
    },
    toStartTest(){
      this.$router.push('/vipTest');
    },

    showStartTestModal(){
      this.show_start_test_modal = true;
    },
    toStartTestPage(){
      this.type = 4;

      if (this.$route.params.startTest) {
        this.queryChallengeInfo().then((response) => {
          this.deduction_exp = response.data.data.next_challenge_deduction - 1;
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import '../viewExplain';
</style>
