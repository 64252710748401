var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 1,
      expression: "type == 1"
    }],
    staticClass: "explain content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("權益須知")]), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.type = 2;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next_page')))])], 1), _c('div', [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toBack
    }
  }, [_vm._v(_vm._s(_vm.$t('return')))])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 2,
      expression: "type == 2"
    }],
    staticClass: "question-description content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("試題說明")]), _vm._m(4), _vm._m(5), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.type = 3;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next_page')))])], 1), _c('div', [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.type = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('return')))])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 3,
      expression: "type == 3"
    }],
    staticClass: "device-check content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("檢查您的設備")]), _vm._m(6), _c('explain-recorder-test'), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.toStartTestPage();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('check_mic')))])], 1), _c('div', [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.type = 2;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('return')))])], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type == 4,
      expression: "type == 4"
    }],
    staticClass: "start content"
  }, [_vm.$route.params.startTest ? _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('startTest')))]) : _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('end_read')))]), _c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/upgrade-test/start.svg",
      "alt": ""
    }
  }), _c('div', [_vm._v("您已閱讀完挑戰說明")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.params.startTest,
      expression: "$route.params.startTest"
    }]
  }, [_vm._v("按下「進入挑戰」開始進行測驗")]), _vm.$route.params.startTest ? _c('div', {
    staticClass: "d-flex flex-column align-items-center mt-4"
  }, [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toStartTest
    }
  }, [_vm._v("回到挑戰")]), _c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showStartTestModal
    }
  }, [_vm._v("進入挑戰")]), _c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'UpgradeTestList'
      }
    }
  }, [_vm._v("想再準備準備")])], 1) : _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    staticClass: "btn",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'UpgradeTestList'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('end_read')))])], 1), _c('b-modal', {
    staticClass: "start-test-modal",
    attrs: {
      "title": "確認進入挑戰?",
      "centered": "",
      "header-text-variant": "light",
      "header-bg-variant": "primary",
      "ok-title": "是",
      "cancel-title": "否"
    },
    on: {
      "ok": function ($event) {
        return _vm.toStartTest();
      }
    },
    model: {
      value: _vm.show_start_test_modal,
      callback: function ($$v) {
        _vm.show_start_test_modal = $$v;
      },
      expression: "show_start_test_modal"
    }
  }, [_c('i', {
    staticClass: "fa fa-exclamation-triangle fa-4x warning-icon",
    attrs: {
      "aria-hidden": "true"
    }
  }), _c('br'), _vm._v("注意!點選進入挑戰後我們將扣除您" + _vm._s(_vm.deduction_exp) + "經驗值! <"), _c('br'), _vm._v("確定要繼續嗎?"), _c('br'), _vm._v("(本操作無法復原)")])], 1), _c('div', {
    staticClass: "btm d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "circle"
  }), _c('div', {
    class: [_vm.type >= 2 ? 'circle' : 'circle-off']
  }), _c('div', {
    class: [_vm.type >= 3 ? 'circle' : 'circle-off']
  }), _c('div', {
    class: [_vm.type >= 4 ? 'circle' : 'circle-off']
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-wrap mb-3"
  }, [_c('div', {
    staticClass: "subtitle col-12 col-md-4"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("升級成功")])]), _c('div', {
    staticClass: "text col-12 col-md-8 pl-md-0"
  }, [_vm._v("本測驗結果"), _c('span', {
    staticClass: "blue-text"
  }, [_vm._v("高於80分(含)即通過")]), _vm._v("，系統將自動為您調升等級。調升後，您的經驗值將重新累計。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-wrap mb-3"
  }, [_c('div', {
    staticClass: "subtitle col-12 col-md-4"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("升級失敗")])]), _c('div', {
    staticClass: "text col-12 col-md-8 pl-md-0"
  }, [_vm._v("若本測驗結果低於80分，將依挑戰次數扣除經驗值（見下表），再次挑戰需重新累積經驗值，才能再次進行升級挑戰。您於閱卷期間所進行之課程，繼續納入經驗值計算。"), _c('table', {
    staticClass: "mt-2"
  }, [_c('tr', [_c('th', [_vm._v("挑戰次數")]), _c('th', [_vm._v("經驗值扣除百分比")])]), _c('tr', [_c('td', [_vm._v("第一次挑戰失敗")]), _c('td', [_vm._v("10%")])]), _c('tr', [_c('td', [_vm._v("第二次挑戰失敗")]), _c('td', [_vm._v("15%")])]), _c('tr', [_c('td', [_vm._v("第三次挑戰失敗")]), _c('td', [_vm._v("20%")])]), _c('tr', [_c('td', [_vm._v("第四次挑戰失敗")]), _c('td', [_vm._v("25%")])]), _c('tr', [_c('td', [_vm._v("第五次挑戰失敗")]), _c('td', [_vm._v("30%")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-wrap mb-3"
  }, [_c('div', {
    staticClass: "subtitle col-12 col-md-4"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("閱卷時間")])]), _c('div', {
    staticClass: "text col-12 col-md-8 pl-md-0"
  }, [_vm._v("測驗結果將於7個工作天內於WUWOW學員後台通知，登入後即可查看。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-wrap mb-4"
  }, [_c('div', {
    staticClass: "subtitle col-12 col-md-4"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("其他")])]), _c('div', {
    staticClass: "text col-12 col-md-8 pl-md-0"
  }, [_vm._v("本測驗並非唯一升級方式,您亦可通過「累積3名顧問升級推薦」提升您的等級。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-4"
  }, [_vm._v("本試題共計12題"), _c('br'), _vm._v("挑戰等級2至等級6，限時作答15分鐘"), _c('br'), _vm._v("挑戰等級7至等級9，限時作答20分鐘"), _c('br'), _vm._v("題目分為下列兩大部分："), _c('div', {
    staticClass: "subtitle col-12 col-md-5 col-xl-5"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("第一部分 單字文法")])]), _c('div', {
    staticClass: "text col-12 col-md-7 col-xl-7 pl-xl-0"
  }, [_vm._v("單字測驗（共5題）-請填入正確選項"), _c('br'), _vm._v("文法測驗（共5題）-請填入正確選項")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-wrap mb-4"
  }, [_c('div', {
    staticClass: "subtitle col-12 col-md-5 col-xl-5"
  }, [_c('span', {
    staticClass: "subtitle-text badge badge-pill badge-primary"
  }, [_vm._v("第二部分  口說")])]), _c('div', {
    staticClass: "text col-12 col-md-7 col-xl-7 pl-xl-0"
  }, [_vm._v("問答題（共2題）-錄音口說回答題"), _c('br'), _vm._v("(請完整表述您的答案，答題時間為一分鐘)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-4"
  }, [_vm._v("因本試題題型包含口說題目，請在考試前檢查"), _c('br'), _vm._v("您的麥克風是否正常，並於安靜無雜音的環境中進行考試(以免干擾閱卷老師批改)。")]);

}]

export { render, staticRenderFns }