const ViewExplainLang = {
  messages: {
    tw: {
      return: '返回',
      view_explain: '觀看挑戰說明',
      startModalText: '進入挑戰 將會扣除100點經驗值 並開始',
      startTest: '進入挑戰',
      end_read: '結束閱讀',
      next_page: '下一頁',
      check_mic: '設備已檢查完畢',
    },
    en: {
      return: 'Return',
      view_explain: '',
      startModalText: '',
      startTest: '',
      end_read: '',
      next_page: 'Next Page',
      check_mic: '',
    },
  },
};

export {
  ViewExplainLang
};
